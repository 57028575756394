'use strict';
DIVERA247.controller('OrderFormController', function ($scope, $timeout, $mdToast) {
  $scope.errors = {cluster_credit_request_brutto: ''};
  $scope.countries = {};
  $scope.tax = {};
  $scope.today = new Date;
  $scope.mainProduct = null;

  $scope.isPeakBilling = false;

  $scope.requiresIndividualOffer = false;
  $scope.requiresIndividualOfferMessage = 'Ihre Konfiguration erfordert ein individuelles Angebot';
  $scope.maxValidPricingPersons = 500;
  $scope.operationMode = 'order';
  $scope.pricing_persons = 10;
  $scope.pricing_persons_peak = 12;


  $scope.diveraAlarmProduct = null;
  $scope.paperBillProduct = null;
  $scope.allProducts = {};
  $scope.products = [];
  $scope.selectedProducts = [];
  $scope.selectedProductsFreetext = {};
  $scope.peakBillingMaxPercentage = 1.2;
  $scope.selectedProductsResolved = [];
  $scope.allowNonSepaPeakPricing = false;
  $scope.prepaidLimits = {
    min: 20,
    max: 200
  };

  $scope.order = {};

  // Tab selection
  $scope.selectedTab = 'address';
  $scope.tabFields = {};

  // AlarmServer order hint
  $scope.hasAlarmServerSelected = false;

  Object.defineProperty($scope, 'isPeakBilling', {
    get: function () {
      return $scope.order.pricing_plan == 0;
    }
  });

  Object.defineProperty($scope,'taxFactor', {
    get: function () {
      var taxForCountry = $scope.tax[$scope.order.cluster_country];

      if (taxForCountry && taxForCountry.standard) return parseInt(taxForCountry.standard, 10);

      return 19;
    }
  });

  Object.defineProperty($scope, 'peakBillingMaxUserCount', {
    get: function () {
      return Math.ceil($scope.peakBillingMaxPercentage * $scope.pricing_persons);
    }
  });

  $scope.$watch('order.pricing_period', function (newVal) {
    $scope.order.pricing_plan = newVal == 12 ? "1" : "0";
  });

  // Preselct SEPA when changing to peak billing
  $scope.$watch('order.pricing_period', function (newVal, oldVal) {
    if (oldVal == null || oldVal == newVal) return;
    /**
    if ($scope.allowNonSepaPeakPricing && newVal != 12 && $scope.order.invoice_type != 2) {
      $mdToast.show($mdToast.simple()
        .textContent('Super-Admin Modus: Wenn benötigt müssen Spitze Abrechnung oder SEPA manuell ausgewählt werden!'));
      return;
    }
    */
    if (newVal == 1 && $scope.order.invoice_type != 2) $scope.order.invoice_type = 2; // invoice_type 2 = SEPA
  });

  $scope.$watch('order.invoice_type', function (newVal, oldVal) {
    if (newVal != 2 && oldVal == 2) {
      $scope.order.invoice_account_iban = '';
      $scope.order.invoice_account_bic = '';
    }
    if (newVal == 3 && oldVal != 3) {
      var idx = $scope.selectedProducts.indexOf($scope.paperBillProduct.id);
      if (idx > -1) {
        $scope.selectedProducts.splice(idx, 1);
      }
    }
  });

  $scope.computeSelectedProductsResolved = function () {
    $scope.selectedProductsResolved = $scope.selectedProducts.map(function (id) {
      var product = $scope.allProducts[id];

      // Hotfix for prepaid "product" calculating the price out of the freetext string
      if (product.sysname === 'prepaid') {
        product = angular.copy(product);
        product.has_setup_fee = 1;
        product.setup_fee = parseFloat($scope.selectedProductsFreetext[product.id]) / (1 + $scope.taxFactor / 100);
        product.setup_fee_brutto = product.setup_fee * (1 + $scope.taxFactor / 100);
      }

      if (product.parent_id === 22 || product.sysname === 'alarmserver-alarm') $scope.hasAlarmServerSelected = true;

      return product;
    });
  };
  $scope.$watch('selectedProducts', function () {
    $scope.computeSelectedProductsResolved();
  });

  $scope.$watch('order.invoice_type', function () {
    $scope.computeSelectedProductsResolved();
  });

  $scope.$watch('pricing_persons', function (newVal, oldVal) {
    if (newVal < oldVal) return;

    var removedProducts = [];

    $scope.selectedProducts = $scope.selectedProducts.filter(function (id) {
      var product = $scope.allProducts[id];

      if (product.has_user_amount_restriction && parseInt(product.amount) < parseInt(newVal)) {
        removedProducts.push(product);
        return false;
      }

      return true;
    });

    // Try to select next selectable variant of a product
    removedProducts.forEach(function (product) {
      if (product.parent_id) {
        var parent = $scope.allProducts[product.parent_id];
        var i = 0;
        var variant;

        while((variant = parent.variants[i++]) && !$scope.isSelectable(variant)) {}

        if (variant && $scope.isSelectable(variant)) {
          $scope.selectedProducts.push(variant.id);
        }
      }
    });
  });

  /**
   * Set the selectedTab
   * @param {string} tabName
   */
  $scope.setSelectedTab = function (tabName) {
    $scope.selectedTab = tabName;
  };

  // List of available tabs
  var tabList = ['address', 'contact', 'modalities', 'components', 'payment', 'invoice'];

  /**
   * Select the next tab within the tab list based on tab name
   * @param {string} currentTabName
   */
  $scope.selectNextTab = function (currentTabName) {
    $scope.selectedTab = tabList[tabList.indexOf(currentTabName) + 1];
  };

  /**
   * Select the previous tab within the tab list based on tab name
   * @param {string} currentTabName
   */
  $scope.selectPrevTab = function (currentTabName) {
    $scope.selectedTab = tabList[tabList.indexOf(currentTabName) - 1];
  };

  /**
   * Check if the tab, based on the tabs name, is the last tab in the list
   * or in order mode the last tab that should be displayed
   * @param {string} currentTabName
   * @returns {boolean}
   */
  $scope.isLastTab = function (currentTabName) {
    var lastTab = tabList[tabList.length-1];

    return (currentTabName === lastTab)
  };

  /**
   * Check if the tab, based on the tabs name, is the first tab in the list
   * @param {string} currentTabName
   * @returns {boolean}
   */
  $scope.isFirstTab = function (currentTabName) {
    var firstTab = tabList[0]

    return (currentTabName === firstTab)
  }

  /**
   * Get selected product variant of parent product
   * @param {object} parent
   * @returns {(null|object)}
   */
  $scope.getSelectedVariant = function (parent) {
    for (var i = 0; i < parent.variants.length; i++) {
      if ($scope.selectedProducts.indexOf(parent.variants[i].id) > -1) return parent.variants[i];
    }

    return null;
  };

  /**
   * Check if parent product has product variants
   * @param {object} parent
   * @returns {boolean}
   */
  $scope.hasSelectableVariants = function (parent) {
    for (var i = 0; i < parent.variants.length; i++) {
      if ($scope.isSelectable(parent.variants[i])) return true;
    }

    return false;
  };

  /**
   * Check if a product is selectable and doesn't have any restrictions applied
   * @param {object} product
   * @returns {boolean}
   */
  $scope.isSelectable = function (product) {
    return !product.hidden &&
      ( !product.has_user_amount_restriction || parseInt(product.amount, 10) >= parseInt($scope.pricing_persons, 10)) &&
      (product.parent_id == 0 || $scope.isSelectable(getProductById(product.parent_id)));
  };

  /**
   * Check if a product has a selected product variant
   * @param {object} product
   * @returns {boolean}
   */
  $scope.hasSelectedVariant = function (product) {
    if (!product) return;

    for (var i = 0; i < product.variants.length; i++) {
      if ($scope.selectedProducts.indexOf(product.variants[i].id) > -1) return true;
    }

    return false;
  };

  /**
   * Initialize product data
   */
  var initProducts = function () {
    var productsDictionary = $scope.allProducts;

    var taxFactor = 1 + ($scope.taxFactor / 100);
    var products = [];

    for (var productId in productsDictionary) {
      if (!productsDictionary.hasOwnProperty(productId)) continue

      var product = productsDictionary[productId];

      product.invoice_fee_brutto = product.invoice_fee * taxFactor
      product.setup_fee_brutto = product.setup_fee * taxFactor;
      product.monthly_fee_brutto = product.monthly_fee * taxFactor;

      switch (product.sysname) {
        case 'dv247alarm':
          $scope.mainProduct = product;
          break;
        case 'papierrechnung':
          $scope.paperBillProduct = product;
          break;
        case 'prepaid':
          var prepaidAmount = $scope.selectedProductsFreetext[product.id] || '20.00';
          $scope.selectedProductsFreetext[product.id] = parseFloat(prepaidAmount);

          // falls through
        default:
          products.push(product);
          break;
      }

      var parentId = product.parent_id;

      if (!parentId || !productsDictionary[parentId]) continue

      var parent = productsDictionary[parentId];

      (parent.variants = parent.variants || []).push(product);

      if (parent.default_variant_product_id)
        parent.defaultVariant = productsDictionary[parent.default_variant_product_id];
    }

    $scope.products = products.sort(function (a, b) {
      var weight = parseInt(a.sorting, 10) - parseInt(b.sorting, 10);

      if (weight !== 0) return weight;

      return (a.title < b.title) ? -1 : (a.title > b.title) ? 1 : 0;
    });

  };

  /**
   * Set operations mode to 'order' or 'order-from-order'
   * @param {string} operationMode
   */
  var setOperationMode = function (operationMode) {
    $scope.operationMode = operationMode ? operationMode : $scope.operationMode;
  };

  /**
   * Initialize controller data
   * @param {object} order
   * @param {object} region
   * @param {object} tax
   * @param {object} selectedProducts
   * @param {object} selectedProductsFreetext
   * @param {object} allProducts
   * @param {number} maxValidPricingPersons
   * @param {string} operationMode
   * @param {(number|string)} peakBillingMaxPercentage
   * @param {string} selectedTab
   * @param {object} tabFields
   */
  $scope.init = function (order, region, tax, selectedProducts, selectedProductsFreetext, allProducts, maxValidPricingPersons, operationMode, peakBillingMaxPercentage, selectedTab, tabFields, allowNonSepaPeakPricing, pricing_persons, pricing_persons_peak, prepaidMin, prepaidMax) {
    $scope.order = unescapeObj(order);
    $scope.countries = unescapeObj(region);
    $scope.tax = unescapeObj(tax);
    $scope.selectedProducts = unescapeObj(selectedProducts || []).map(function (product) {
      return parseInt(product, 10);
    });
    $scope.allProducts = unescapeObj(allProducts);
    $scope.selectedProductsFreetext = unescapeObj(selectedProductsFreetext);
    $scope.maxValidPricingPersons = maxValidPricingPersons || 500;
    $scope.allowNonSepaPeakPricing = allowNonSepaPeakPricing;
    $scope.pricing_persons = pricing_persons;
    $scope.pricing_persons_peak = pricing_persons_peak;
    $scope.prepaidLimits = {
      min: prepaidMin || $scope.prepaidLimits.min,
      max: prepaidMax || $scope.prepaidLimits.max
    };

    $scope.peakBillingMaxPercentage = parseInt(peakBillingMaxPercentage, 10) / 100;

    if (selectedTab) $scope.selectedTab = selectedTab;
    $scope.tabFields = JSON.parse(unescapeObj(tabFields));

    // Select payment method depending on invoice type
    $scope.onPricingPeriodChange();

    setOperationMode(operationMode)

    // enrich product details
    initProducts();
    $scope.calc();
  };

  /**
   * Get product by productId
   * @param {number} productId
   * @param {object} products
   */
  var getProductById = function (productId, products) {
    if (!products) products = $scope.products;

    for (var i = 0; i < products.length; i++) {
      var product = products[i];

      if (product.id === productId) return product;
    }

    return null;
  };

  /**
   * Deselect product variant and remove it from selectedProducts array
   * @param {object} parent
   */
  function deselectProductVariants(parent) {
    if (!parent.variants) return

    parent.variants.forEach(function (variant) {
      var index = $scope.selectedProducts.indexOf(variant.id);

      if (index > -1) $scope.selectedProducts.splice(index, 1);
    });
  };

  /**
   * Set PricingPersonPeak amount equal to PricingPersons amount for monthly billing
   */
  $scope.onPricingPeriodChange = function () {
    if ($scope.order.pricing_period == 1) $scope.pricing_persons_peak = $scope.pricing_persons
  };

  /**
   * Recalculate price on changed amount of PricingPersons
   */
  $scope.onPricingPersonsChange = function () {
    $scope.calc()

    if ($scope.order.pricing_period == 1) $scope.pricing_persons_peak = $scope.pricing_persons

    var requiresIndividualOffer = parseInt($scope.pricing_persons) > $scope.maxValidPricingPersons
    $scope.requiresIndividualOffer = requiresIndividualOffer

    if (requiresIndividualOffer) {
      $scope.requiresIndividualOfferMessage = 'Bei mehr als ' + $scope.maxValidPricingPersons + ' Benutzern benötigen Sie ein individuelles Angebot'
    }
  }

  /**
   * Get selected product variant for parent product
   * @param {number} parentId
   */
  $scope.getSelectedVariantIdForProduct = function (parentId) {
    var parent = getProductById(parentId);

    if (!parent || !parent.variants) return 0

    var variants = parent.variants;
    var numVariants = variants.length;

    var i = 0;
    for (; i < numVariants; i++) {
      var variantId = variants[i].id;
      var index = $scope.selectedProducts.indexOf(variantId);

      if (index < 0) continue

      return variantId
    }

    return 0
  };

  /**
   * Toggle the display of product variant in the frontend (components tab) and recalculate price
   * @param {object} parent
   */
  $scope.toggleVariantListing = function (parent) {
    var isShown = $scope.hasSelectedVariant(parent);

    if (!isShown) {
      var i = 0;
      var variant = parent.defaultVariant;

      while (variant && !$scope.isSelectable(variant)) {
        variant = parent.variants[i++];
      }

      if ($scope.isSelectable(variant)) $scope.selectProductVariant(parent, variant);
    } else {
      deselectProductVariants(parent);
    }

    $scope.calc();
  };

  /**
   * Select a product variant and recalculate price
   * @param {object} parent
   * @param {object} variant
   */
  $scope.selectProductVariant = function (parent, variant) {
    if ($scope.selectedProducts.indexOf(variant.id) > 0) return;

    deselectProductVariants(parent);

    $scope.selectedProducts.push(variant.id);
    $scope.calc();
  };

  /**
   * Toggle product selection
   * @param {number} productId
   */
  $scope.toggleProductSelection = function (productId) {
    var index = $scope.selectedProducts.indexOf(productId);

    if (index < 0) {
      $scope.selectedProducts.push(productId);
    } else {
      $scope.selectedProducts.splice(index, 1);
    }

    $scope.calc();
  };

  /**
   * Calculate the usable amount of prepaid credits
   * @param {number} prepaidAmount
   * @returns {boolean}
   */
  var calcPrepaid = function (prepaidAmount) {
    if (typeof prepaidAmount !== 'number') {
      $scope.errors.cluster_credit_request_brutto = 'Format ungültig, bitte ganze Zahlen angeben. ';
    }

    if (prepaidAmount < $scope.prepaidLimits.min) {
      $scope.errors.cluster_credit_request_brutto += 'Mindestbetrag ' + $scope.prepaidLimits.min + ',- €. ';
    }

    if (prepaidAmount > $scope.prepaidLimits.max) {
      $scope.errors.cluster_credit_request_brutto += 'Maximalbetrag ' + $scope.prepaidLimits.max + ',- €. ';
    }

    if ($scope.errors.cluster_credit_request_brutto) {
      $scope.pricePrepaidBrutto = 0;
      $scope.pricePrepaidNetto = 0;
      $scope.pricePrepaidUst = 0;
      return false
    }

    $scope.pricePrepaidBrutto = parseFloat(prepaidAmount);
    $scope.pricePrepaidNetto = $scope.pricePrepaidBrutto / (100 + $scope.taxFactor) * 100;
    $scope.pricePrepaidUst = $scope.pricePrepaidBrutto / (100 + $scope.taxFactor) * $scope.taxFactor;

    return true;
  };

  /**
   * Recalculate the total order price
   */
  $scope.calc = function () {
    $scope.errors.cluster_credit_request_brutto = '';

    $scope.priceSponsoringBrutto = ($scope.order.sponsoring) ? $scope.priceSponsoringBrutto : 0;

    var taxFactor = 1 + ($scope.taxFactor / 100);
    var invoiceFeeTotalNetto = 0;
    var productsTotalNetto = 0;
    var productsTotalSetupNetto = 0;

    $scope.selectedProducts.forEach(function (productId) {
      if (!$scope.allProducts.hasOwnProperty(productId)) return

      var product = $scope.allProducts[productId];

      var monthlyFee = product.monthly_fee;
      var setupFee = product.setup_fee;
      var invoiceFee = product.invoice_fee;

      switch (product.sysname) {
        case 'dv247alarm':
          monthlyFee = monthlyFee * $scope.pricing_persons;
          break;

        case 'prepaid':
          var amount = parseFloat($scope.selectedProductsFreetext[productId]);
          calcPrepaid(amount);

          monthlyFee = 0;
          setupFee = $scope.pricePrepaidNetto;
          break;
      }

      invoiceFeeTotalNetto += invoiceFee;
      productsTotalNetto += monthlyFee;
      productsTotalSetupNetto += setupFee;
    });




    $scope.priceTotalProductsNetto = productsTotalNetto;
    $scope.priceTotalProductsBrutto = productsTotalNetto * taxFactor;

    $scope.priceTotalProductsSetupBrutto = productsTotalSetupNetto * taxFactor;
    $scope.priceTotalProductsSetupNetto = productsTotalSetupNetto;
    $scope.priceTotalNetto = /*($scope.isPeakBilling ? 0 : */ productsTotalNetto /*)*/ * parseInt($scope.order.pricing_period) + $scope.priceTotalProductsSetupNetto;/* + $scope.priceSponsoringBrutto; */

    $scope.priceTotalNetto += invoiceFeeTotalNetto;

    $scope.priceTotalBrutto = $scope.priceTotalNetto * taxFactor;
    $scope.priceTotalUst = $scope.priceTotalBrutto - $scope.priceTotalNetto;

    return true;
  }

  /**
   * Check tabs for frontend vaildation errors and set the selectedTab to tab with first error
   */
  $scope.submit = function () {
    if (!$scope.form.$invalid) {
      return;
    }

    var inputName = $scope.form.$error[Object.keys($scope.form.$error)[0]][0].$name
    var cleanInputName = inputName.slice(0, -1).slice(6)
    var selectTab = null;

    for (var key in $scope.tabFields) {
      for (var fieldKey in $scope.tabFields[key]) {
        if ($scope.tabFields[key][fieldKey] === cleanInputName)  {
          selectTab = key;
          break;
        }
      }

      if (selectTab !== null) {
        $scope.selectedTab = selectTab;
        break;
      }
    }

    $mdToast.showSimple('Bitte prüfen Sie alle Felder auf Ihre Gültigkeit. Bei Fragen oder Problemen kontaktieren Sie unseren Support.');
  }
});
